// config.sats.ts
//
// Configuration options
//

// Server config production mode
const production =
  process.env.CONFIG_MODE !== undefined
    ? process.env.CONFIG_MODE === 'production'
    : process.env.NODE_ENV === 'production'

const noSSL = process.env.NO_SSL !== undefined ? process.env.NO_SSL === 'true' : false
if (!noSSL && process.env.REACT_APP_UNENCRYPTED_BACKEND === 'true') {
  throw new Error(
    `noSSL=true and REACT_APP_UNENCRYPTED_BACKEND=${process.env.REACT_APP_UNENCRYPTED_BACKEND} are incompatible, please fix your config.`
  ) // eslint-disable-line max-len
}

const defaultSslPath = './server/src/keys'

const clientHostPort = production ? null : process.env.CLIENT_HOST_PORT || '3000'

const config: configType = {
  version: '6.4.1',
  appName: 'SATS Checkin App',
  production,

  // enable/disable debug messages
  debug: process.env.DEBUG !== undefined ? process.env.DEBUG === 'true' : !production,
  self_test: process.env.SELF_TEST !== undefined ? process.env.SELF_TEST === 'true' : !production,

  no_ssr: process.env.NO_SSR !== undefined ? process.env.NO_SSR === 'true' : false,
  node_stream: process.env.NODE_STREAM !== undefined ? process.env.NODE_STREAM === 'true' : false,
  pipe_stream: process.env.PIPE_STREAM !== undefined ? process.env.PIPE_STREAM === 'true' : false,

  // ssl server config
  no_ssl: noSSL,
  ssl_email: 'ssl@swinginatthesavoy.com',
  https_force: process.env.FORCE_HTTPS !== undefined ? process.env.FORCE_HTTPS !== 'false' : true,
  domains: [process.env.DOMAIN || 'checkin.swinginatthesavoy.com'],
  // client
  host: process.env.HOST || 'localhost',
  host_port: clientHostPort,
  // server
  http_port: process.env.PORT_HTTP || '8888',
  https_port: process.env.PORT_HTTPS || '8443',
  ssl_path: process.env.SSL_PATH || defaultSslPath,
  use_proxy: process.env.USE_PROXY !== undefined ? process.env.USE_PROXY === 'true' : false,

  // cloudflare
  cloudflare_email: process.env.CLOUDFLARE_EMAIL || 'cloudflare@wednesdaynighthop.com',
  cloudflare_api_key: process.env.CLOUDFLARE_API_KEY || 'cd8cfab2b2f0e7e839ecde27a8238457c2be8',

  // wordpress server
  url: process.env.WC_URL || 'https://swinginatthesavoy.com',

  // oauth
  oauthapi_path: '/oauth',
  oauth_authorize_path: '/oauth/authorize',
  oauth_port: clientHostPort,
  // eslint-disable-next-line no-nested-ternary,max-len
  oauth_client_id: production
    ? 'pVhJ2aVfbxlYLEKtioCB2K8TgC8nzQ4PXhI493EX'
    : process.env.CLIENT_HOST_PORT === '8443'
    ? '4ff3QBSJff82mzZmfl7BqR9YuSabmphloVD5kw2r'
    : 'Yx5uxrg4KDeDBrvd7Y6pUZpcmyJzQlu4NzFIuJjB',
  // eslint-disable-next-line no-nested-ternary,max-len
  oauth_client_secret: production
    ? 'uHbtgWJdNvaVUjM8m63eMIMg8ZAAJd77One4LR3m'
    : process.env.CLIENT_HOST_PORT === '8443'
    ? 'WAZ3OjDs8K9k1qYeOTSSlV6Ds1EQqxUxDzezjosu'
    : '4efW7Hs6yRoiYWaq9DtJipfoNwFIrkgHWWxdVqd9',
  oauth_token_path: '/oauth/token',
  oauth_grant_type: 'authorization_code',
  oauth_state_secret: '@9&2^48*',
  oauth_redirect_path: '/oauth_redirect/',
  oauth_user_regexp: '^(checkin_app_(user|teachers)|gilles)$',

  // WooCommerce REST API
  wcapi_version: 'wc/v3',
  wcapi_path: 'satsapi',
  webhook_port: clientHostPort,
  webhook_api_path: 'api/webhook',
  webhook_types: ['order'],
  wcapi_users: {
    default: {
      consumerKey: process.env.WCAPI_KEY || 'ck_95ca63dc28ba0cebae8abde295000c7e80dbebf8',
      consumerSecret: process.env.WCAPI_SECRET || 'cs_47b857a309c62e1fe18b841d54868e5c45f2bada',
    },
  } as {
    [key in checkin.userRoleEnum]: {
      consumerKey: string
      consumerSecret: string
    }
  },

  // The Events Calendar REST API
  hasEvents: false,
  // tecapi_version: 'tribe/events/v1',
  // tecapi_path: 'satsapi',
  // currently uses oauth_token below for authentication

  // WordPress REST API
  wpapi_version: 'wp/v2',
  wpapi_path: 'satsapi',
  // Auth token using application password (generated with: echo -n "USERNAME:PASSWORD" | base64)
  // user: checkin_app_user
  auth_token: 'Y2hlY2tpbl9hcHBfdXNlcjpLb1ZpIFJnRmcgRHVWTiBtcGVVIDRVdFAgRFBlYQ==',

  // Wordpress GraphQL
  wpgraphql_endpoint: 'satsgraphql',

  // Redis
  redis_port: process.env.REDIS_PORT || (production ? '6380' : '6379'),
  redis_host: process.env.REDIS_SERVER || (production ? 'redis' : '127.0.0.1'),
  redis_prefix: 'namespace:satscheckinapp-',

  // session
  secure_cookie: production,
  samesite_cookie: 'strict',

  // users
  users: {
    test_user: 3,
  },

  // orders
  orders: {
    statuses: ['completed', 'processing', 'on-hold', 'pending'],
    // statuses for order lookup
    lookupStatuses: ['completed', 'processing', 'on-hold', 'pending', 'refunded'],
  },

  // products
  products: {
    test_product: 67964,

    // FIXME: does not work because we don't have events
    // we always want to load the guest pass
    always_load_slugs: ['sats-2025-guess-pass'],

    event_track_pass_product: {
      id: 89381,
      variations: [
        // added here because newcomers are not in the placement file
        { id: 89382, track: 'Newcomers Track' },
      ],
    },
    guest_pass_product: {
      id: 89453,
      track: 'VIP',
      variations: [{ id: 89455, track: 'Weekend Dance Pass' }],
    },
  },

  // store credit
  support_store_credit: false,

  // track placements
  use_placements: true,

  /**
   * Google sheets
   *
   * This requires to upload credentils on the OLS server under:
   *   "CREDENTIALS_PATH": "/etc/google/checkin.swinginatthesavoy.com"
   */
  googlesheets: {
    credentials_path: process.env.CREDENTIALS_PATH || process.env.SSL_PATH || defaultSslPath,
    credentials_filename: 'google_service_account_credentials.json',
    scope: 'https://www.googleapis.com/auth/spreadsheets.readonly',
    files: {
      placements: {
        spreadsheetId: '1hEkwonok-n9IYtbV-hgWtAoKC-0eg-2JtN1w_Z_0kzs',
        range: 'Placements!B2:I300',
        nameRowNumber: 0,
        emailRowNumber: 1,
        trackRowNumber: 4,
      },
    },
  },
}

export default config
