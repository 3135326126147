import { createLogic } from 'redux-logic'
import debug from 'debug'
import {
  MetaDataActionTypes,
  metaDataCancel,
  metaDataFulfilled,
  metaDataRejected,
  metaDataRequest,
} from './reducer'
import { RootState, Dependency } from 'AppSrc/store'
import { MetaDataPayloadDataType } from './types'

debug.enable('metadata/logic:*')
const log = debug('metadata/logic:log')
// const info = debug('metadata/logic:info')
const error = debug('metadata/logic:error')

// const delay = 4 // 4s delay for interactive use of cancel/take latest

export const metaDataLogic = createLogic<
  RootState, // State
  [string, MetaDataPayloadDataType], // Payload
  any, // Meta
  Dependency, // Dependency
  MetaDataActionTypes // Context
>({
  type: metaDataRequest.type,
  cancelType: metaDataCancel.type,
  // latest: true, // take latest only

  // use axios injected as httpClient from configureStore logic deps
  // we also have access to getState and action in the first argument
  // but they were not needed for this particular code
  async process({ httpClient, getState, action }, dispatch, done) {
    const idx = action.payload[0]
    const data = action.payload[1]

    try {
      // Update user information if any
      if (data.user) {
        const { userId, meta } = data.user
        await httpClient.put(`/api/users/${encodeURIComponent(userId)}?reqId=client`, { meta })
      }

      // Update orders information if any
      if (data.orders) {
        const { regId, orderIds, orderExtra } = data.orders
        const dataToUpdate = orderIds.map(orderId => ({
          orderId,
          order_extra: orderExtra,
        }))
        await httpClient.put(
          `/api/registrants/${encodeURIComponent(btoa(regId))}?reqId=client&filterByItems=true`,
          dataToUpdate
        )
      }

      dispatch(metaDataFulfilled(idx)) // FIXME? should we check resp?
    } catch (err) {
      error(err) // might be a render err
      dispatch(metaDataRejected(idx, err as Error))
    } finally {
      done()
    }
  },
})

export default metaDataLogic
